@tailwind base;
@tailwind components;
@tailwind utilities;


html, body {
  background: #000;
  color: #fff;
  font-size: 14px;
  height:100vh;
}

#root {
  
}