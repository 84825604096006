.balance-container img {
  width: 30px;
  padding-top: 2px;
}

.balance-container div {
  padding-right: 20px;
}

.progress-overlay {
  background: rgb(47 47 47);
}

.claim-button {
  background: #ef8619;
  padding: 10px 20px;
  border-radius: 20px;
  width: 100%;
  box-shadow: 0px 0px 20px -5px #d89d1d;
}

.claim-button:active, .claim-button:active {
  background: #fda500;
}

.farm-container {
  position: relative;
  width: 100%;
  aspect-ratio: 1;
}

.farm-container img {
  z-index: 2;
    position: relative;
    width: 80%;
    height: auto;
    top: 10%;
    left: 10%;
}

.farm-light {
  position: absolute;
  width: 108%;
  height: 108%;
  top: 0%;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
  background: radial-gradient(circle, rgba(250, 184, 2, 0.8) 0%, rgba(255, 223, 0, 0) 70%);
  /* background: radial-gradient(circle, rgb(250 205 2 / 80%) 0%, rgba(255, 223, 0, 0) 70%); */
  pointer-events: none;
}

.farm-small-light {
  position: absolute;
  width: 20%;
  height: 20%;
  top: 40%;
  left: 55%;
  transform: translateX(-50%);
  background: radial-gradient(circle, rgb(239 134 25) 0%, rgba(255, 223, 0, 0) 70%);
  pointer-events: none;
}

.blinking-container {
  animation: blink-animation 3s infinite;
}

.blinking-container-small {
  animation: blink-animation-small 5s infinite;
}

@keyframes blink-animation-small {
  0% {
      opacity: 0.6;
  }
  50% {
      opacity: 0.8;
  }
  100% {
      opacity: 0.6;
  }
}

@keyframes blink-animation {
  0% {
      opacity: 0.6;
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: 0.6;
  }
}

.boost_bg {
  position: absolute;
  opacity: 0.9;
  top: 0;
  left: 0;
  width: 100%;
  aspect-ratio: auto;
}