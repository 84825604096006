.tabbar {
  position: fixed;
  bottom: 10px;
  width: 100%;
}

.tabbar-button.active {
  color: #fff;
}

.tabbar-button:hover {
  background-color: #000;
}

.tabbar-button span {
  font-size: 11px;
}