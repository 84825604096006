.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;
  justify-content: flex-end;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.overlay.active {
  visibility: visible;
  opacity: 1;
}

.modal {
  position: absolute;
  transition: bottom 0.5s ease;
  bottom: -100%;
  left: 0;
  width: 100%;
  min-height: 30%;
  overflow: hidden;
  z-index: 105;
}

.modal.active {
  bottom: 0%;
}