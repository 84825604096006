.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Высота контейнера лоадера */
}

.loader {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #3b82f6 transparent #3b82f6 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite, gradient 2.4s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes gradient {
  0%, 100% {
    border-color: #3b82f6 transparent #3b82f6 transparent;
  }
  50% {
    border-color: #ef4444 transparent #ef4444 transparent;
  }
}
